/* Layouts */
.navbar {
  &.fixed-top {
    + .page-body-wrapper {
      padding-top: $navbar-height;
    }
  }
}

// Sidebar Mini
.sidebar-mini {
  @media (min-width: 992px) {
    .navbar {
      left: $sidebar-width-mini;
      .navbar-brand-wrapper {
        width: $sidebar-width-mini;
      }
      .navbar-menu-wrapper {
        width: 100%;
      }
    }
    .sidebar {
      width: $sidebar-width-mini;
      .sidebar-brand-wrapper {
        width: $sidebar-width-mini;
      }
      .nav {
        .nav-item {
          padding: 0;
          margin: 0;
          &.menu-items {
            .nav-link {
              .menu-icon {
                margin-left: auto;
                margin-right: auto;
              }
            }
          }
          .nav-link {
            text-align: center;
            position: relative;
            .menu-title {
              display: inline-block;
            }
            .menu-sub-title {
              margin: auto;
            }
            .badge {
              margin-left: 5px;
              display: none;
            }
            i {
              &.menu-icon {
                display: block;
                margin-right: auto;
                margin-left: auto;
              }
              &.menu-arrow {
                display: inline-block;
                margin-left: 5px;
                position: absolute;
                top: 50%;
                right: 10px;
                @include transform(translateY(-50%));
                &:before {
                  content: '\F140';
                }
              }
            }
            &[aria-expanded='true'] {
              .menu-arrow {
                &:before {
                  content: '\f143';
                }
              }
            }
            .menu-icon {
              margin-right: 1.25rem;
              margin-left: 0;
            }
          }
          &.nav-category {
            display: none;
          }
          &.profile {
            .profile-desc {
              justify-content: center;
              position: relative;
              .profile-pic {
                flex-direction: column;
              }
              .profile-name {
                margin-left: 0;
              }
              .profile-dropdown {
                position: absolute;
                right: 10px;
              }
            }
          }
        }
      }
    }
    &:not(.sidebar-icon-only) {
      //Apply these styles only when sidebar-mini is not collapsed to icon-only mode
      .sidebar {
        .nav {
          &:not(.sub-menu) {
            > .nav-item {
              border-bottom: 1px solid rgba($sidebar-menu-color, 0.2);
              .nav-link {
                display: block;
                height: auto;
                padding: $sidebar-mini-menu-padding;
                i {
                  &.menu-icon {
                    margin-bottom: 0.5rem;
                  }
                }
              }
            }
          }
          &.sub-menu {
            padding: 0;
            border-top: none;
            .nav-item {
              .nav-link {
                padding: 1rem 0rem;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
              }
            }
          }
          .nav-item {
            &.account-dropdown {
              p {
                margin-left: 0;
              }
              .sub-menu {
                padding: 0 0 1rem 0;
                .nav-item {
                  .nav-link {
                    display: flex;
                    justify-content: flex-start;
                    padding: 0.3rem 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    .page-body-wrapper {
      width: calc(100% - #{$sidebar-width-mini});
    }
  }
}

// Sidebar Icon Only
.sidebar-icon-only {
  @media (min-width: 992px) {
    .navbar {
      left: $sidebar-width-icon;
      .navbar-menu-wrapper {
        width: 100%;
      }
    }
    .sidebar {
      width: $sidebar-width-icon;
      .sidebar-brand-wrapper {
        width: $sidebar-width-icon;
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: inline-block;
        }
      }
      .nav {
        overflow: visible;
        .nav-item {
          position: relative;
          padding: 0;
          .profile-name {
            display: none;
          }
          .mdi-dots-vertical {
            display: none;
          }
          &.account-dropdown {
            display: none;
          }
          .nav-link {
            text-align: center;
            .menu-title,
            .badge,
            .menu-sub-title {
              display: none;
            }
            .menu-title {
              @include border-radius(0 5px 5px 0px);
              @at-root #{selector-append(".rtl", &)} {
                @include border-radius(5px 0 0 5px);
              }
            }
            i {
              &.menu-icon {
                margin-right: 0;
                margin-left: 0;
              }
              &.menu-arrow {
                display: none;
              }
            }
            &[aria-expanded] {
              .menu-title {
                @include border-radius(0 5px 0 0px);
                @at-root #{selector-append(".rtl", &)} {
                  @include border-radius(5px 0 0 0);
                }
              }
            }
          }
          &.nav-profile {
            display: none;
          }
          &.nav-category {
            display: none;
          }
          &.nav-doc {
            margin: 0;
            i {
              display: block;
            }
          }
          .collapse {
            display: none;
          }
          &.hover-open {
            .nav-link {
              .menu-title {
                @include display-flex;
                @include align-items(center);
                background: $icon-only-menu-bg;
                padding: 0.5rem 1.4rem;
                left: $sidebar-width-icon;
                position: absolute;
                text-align: left;
                top: 0;
                bottom: 0;
                width: $icon-only-collapse-width;
                z-index: 1;
                line-height: 1.8;
                @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right: $sidebar-width-icon;
                  text-align: left;
                }
                &:after {
                  display: none;
                }
              }
            }
            .collapse,
            .collapsing {
              display: block;
              padding: 0.5rem 0;
              @include border-radius(0 0 5px 0);
              background: $icon-only-menu-bg;
              position: absolute;
              left: $sidebar-width-icon;
              width: $icon-only-collapse-width;
              @at-root #{selector-append(".rtl", &)} {
                left: auto;
                right: $sidebar-width-icon;
                @include border-radius(0 0 0 5px);
              }
            }
          }
        }
        &.sub-menu {
          padding: $sidebar-icon-only-submenu-padding;
          .nav-item {
            .nav-link {
              text-align: left;
              padding-left: 20px;
              &:before {
                content: '';
                display: inline-block;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: $sidebar-submenu-color;
                position: absolute;
                left: 0;
              }
              &:hover {
                &:before {
                  background-color: white;
                }
              }
            }
          }
          @at-root #{selector-append(".rtl", &)} {
            .nav-item {
              margin-left: 0;
              .nav-link {
                text-align: right;
                &:before {
                  left: 0;
                  right: unset;
                }
              }
            }
          }
        }
      }
      .sidebar-actions {
        display: none;
      }
    }
    &.rtl {
      .sidebar {
        .nav {
          .nav-item {
            &.profile {
              padding-right: 0;
            }
          }
        }
      }
    }
    .page-body-wrapper {
      width: calc(100% - #{$sidebar-width-icon});
      transition: all $action-transition-duration
        $action-transition-timing-function;
      -webkit-transition: all $action-transition-duration
        $action-transition-timing-function;
      -moz-transition: all $action-transition-duration
        $action-transition-timing-function;
      -ms-transition: all $action-transition-duration
        $action-transition-timing-function;
    }
  }
}

// Hidden Sidebar
.sidebar-hidden {
  @media (min-width: 992px) {
    .sidebar {
      transition: width $action-transition-duration
        $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration
        $action-transition-timing-function;
      -moz-transition: width $action-transition-duration
        $action-transition-timing-function;
      -ms-transition: width $action-transition-duration
        $action-transition-timing-function;
      width: 0;
    }
    .page-body-wrapper {
      width: 100%;
    }
  }
}

// Absolute sidebar with overlay to content
.sidebar-absolute {
  @media (min-width: 992px) {
    .page-body-wrapper {
      width: 100%;
      position: relative;
    }
    &:not(.sidebar-hidden) {
      .sidebar {
        position: absolute;
        height: 100%;
        -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
        /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
        -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
        /* Firefox 3.5 - 3.6 */
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
        /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
      }
    }
    .sidebar {
      transition: none;
    }
  }
}

//Fixed sidebar
.sidebar-fixed {
  @media (min-width: 992px) {
    .sidebar {
      position: fixed;
      max-height: auto;
      .nav {
        max-height: 100vh;
        overflow: auto;
        position: relative;
        &.sub-menu {
          max-height: none;
        }
      }
    }
    .page-body-wrapper {
      margin-left: $sidebar-width-lg;
    }
    &.sidebar-icon-only {
      .page-body-wrapper {
        margin-left: $sidebar-width-icon;
      }
    }
  }
}

//Boxed layout
.boxed-layout {
  @media (min-width: 1200px) {
    .container-scroller {
      background: #71bfec;
      padding: 0 calc((100% - #{$boxed-container-width}) / 2);
    }
    .navbar {
      right: calc((100% - #{$boxed-container-width}) / 2);
      left: calc(
        ((100% - #{$boxed-container-width}) / 2) + #{$sidebar-width-lg}
      );
      &.fixed-top {
        margin: auto;
        max-width: $boxed-container-width;
      }
    }
    .sidebar {
      .sidebar-brand-wrapper {
        left: calc((100% - #{$boxed-container-width}) / 2);
      }
    }
    &.sidebar-icon-only {
      .navbar {
        left: calc(
          ((100% - #{$boxed-container-width}) / 2) + #{$sidebar-width-icon}
        );
      }
    }
  }
}

//RTL layout
.rtl {
  direction: rtl;
  text-align: right;
  .sidebar {
    .sidebar-brand-wrapper {
      .sidebar-brand {
        padding: 0.25rem 1.3rem 0rem 0rem;
      }
    }
    .nav {
      padding-right: 0;
      .nav-item {
        &.account-dropdown {
          .nav-link {
            p {
              margin-right: 1rem;
            }
          }
        }
        .nav-link {
          .menu-icon {
            margin-right: 0;
            margin-left: 0.5em;
          }
        }
      }
      &.sub-menu {
        padding: $rtl-sidebar-submenu-padding;
      }
    }
  }

  .product-chart-wrapper,
  .settings-panel .tab-content .tab-pane .scroll-wrapper,
  .sidebar-fixed .nav,
  .table-responsive,
  ul.chats {
    &::-webkit-scrollbar {
      width: 0.5em;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
  .navbar {
    @media (min-width: 992px) {
      right: $sidebar-width-lg;
      left: 0;
      transition: left $action-transition-duration
        $action-transition-timing-function;
      -webkit-transition: right $action-transition-duration
        $action-transition-timing-function;
      -moz-transition: right $action-transition-duration
        $action-transition-timing-function;
      -ms-transition: right $action-transition-duration
        $action-transition-timing-function;
    }
    .navbar-brand-wrapper {
      .navbar-brand {
        padding: 0.25rem 1.3rem 0rem 0rem;
      }
    }
    .navbar-menu-wrapper {
      .navbar-nav {
        padding: 0;
        .nav-item {
          .nav-link {
            .navbar-profile {
              .navbar-profile-name {
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }
  }
  .dropdown-menu {
    text-align: right;
  }
  &.sidebar-icon-only {
    .navbar {
      right: $sidebar-width-icon;
    }
    .sidebar {
      .nav {
        &.sub-menu {
          .nav-item {
            .nav-link {
              padding-right: 20px;
              &:before {
                right: 0;
                left: unset;
              }
            }
          }
        }
      }
    }
  }
}
